import React from 'react';

interface Props {
  emoji: string;
  name: string;
}
const Emoji = ({ emoji, name }: Props) => (
  <span role="img" aria-label={name}>
    {emoji}
  </span>
);

export default Emoji;
